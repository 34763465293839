import * as _react2 from "react";
var _react = "default" in _react2 ? _react2.default : _react2;
import * as _reactDom2 from "react-dom";
var _reactDom = "default" in _reactDom2 ? _reactDom2.default : _reactDom2;
import _utils from "./utils";
import _scrollSpy from "./scroll-spy";
import _scroller from "./scroller";
import * as _propTypes2 from "prop-types";
var _propTypes = "default" in _propTypes2 ? _propTypes2.default : _propTypes2;
import _scrollHash from "./scroll-hash";
var exports = {};
/* DEPRECATED */

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];
    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }
  return target;
};
var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }
  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();
function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}
function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }
  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}
function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}
var React = _react;
var ReactDOM = _reactDom;
var utils = _utils;
var scrollSpy = _scrollSpy;
var defaultScroller = _scroller;
var PropTypes = _propTypes;
var scrollHash = _scrollHash;
var protoTypes = {
  to: PropTypes.string.isRequired,
  containerId: PropTypes.string,
  container: PropTypes.object,
  activeClass: PropTypes.string,
  spy: PropTypes.bool,
  smooth: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  offset: PropTypes.number,
  delay: PropTypes.number,
  isDynamic: PropTypes.bool,
  onClick: PropTypes.func,
  duration: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
  absolute: PropTypes.bool,
  onSetActive: PropTypes.func,
  onSetInactive: PropTypes.func,
  ignoreCancelEvents: PropTypes.bool,
  hashSpy: PropTypes.bool,
  spyThrottle: PropTypes.number
};
var Helpers = {
  Scroll: function Scroll(Component, customScroller) {
    console.warn("Helpers.Scroll is deprecated since v1.7.0");
    var scroller = customScroller || defaultScroller;
    var Scroll = function (_React$Component) {
      _inherits(Scroll, _React$Component);
      function Scroll(props) {
        _classCallCheck(this, Scroll);
        var _this = _possibleConstructorReturn(this, (Scroll.__proto__ || Object.getPrototypeOf(Scroll)).call(this, props));
        _initialiseProps.call(_this);
        _this.state = {
          active: false
        };
        return _this;
      }
      _createClass(Scroll, [{
        key: "getScrollSpyContainer",
        value: function getScrollSpyContainer() {
          var containerId = this.props.containerId;
          var container = this.props.container;
          if (containerId) {
            return document.getElementById(containerId);
          }
          if (container && container.nodeType) {
            return container;
          }
          return document;
        }
      }, {
        key: "componentDidMount",
        value: function componentDidMount() {
          if (this.props.spy || this.props.hashSpy) {
            var scrollSpyContainer = this.getScrollSpyContainer();
            if (!scrollSpy.isMounted(scrollSpyContainer)) {
              scrollSpy.mount(scrollSpyContainer, this.props.spyThrottle);
            }
            if (this.props.hashSpy) {
              if (!scrollHash.isMounted()) {
                scrollHash.mount(scroller);
              }
              scrollHash.mapContainer(this.props.to, scrollSpyContainer);
            }
            if (this.props.spy) {
              scrollSpy.addStateHandler(this.stateHandler);
            }
            scrollSpy.addSpyHandler(this.spyHandler, scrollSpyContainer);
            this.setState({
              container: scrollSpyContainer
            });
          }
        }
      }, {
        key: "componentWillUnmount",
        value: function componentWillUnmount() {
          scrollSpy.unmount(this.stateHandler, this.spyHandler);
        }
      }, {
        key: "render",
        value: function render() {
          var className = "";
          if (this.state && this.state.active) {
            className = ((this.props.className || "") + " " + (this.props.activeClass || "active")).trim();
          } else {
            className = this.props.className;
          }
          var props = _extends({}, this.props);
          for (var prop in protoTypes) {
            if (props.hasOwnProperty(prop)) {
              delete props[prop];
            }
          }
          props.className = className;
          props.onClick = this.handleClick;
          return React.createElement(Component, props);
        }
      }]);
      return Scroll;
    }(React.Component);
    var _initialiseProps = function _initialiseProps() {
      var _this2 = this;
      this.scrollTo = function (to, props) {
        scroller.scrollTo(to, _extends({}, _this2.state, props));
      };
      this.handleClick = function (event) {
        /*
         * give the posibility to override onClick
         */

        if (_this2.props.onClick) {
          _this2.props.onClick(event);
        }

        /*
         * dont bubble the navigation
         */

        if (event.stopPropagation) event.stopPropagation();
        if (event.preventDefault) event.preventDefault();

        /*
         * do the magic!
         */
        _this2.scrollTo(_this2.props.to, _this2.props);
      };
      this.stateHandler = function () {
        if (scroller.getActiveLink() !== _this2.props.to) {
          if (_this2.state !== null && _this2.state.active && _this2.props.onSetInactive) {
            _this2.props.onSetInactive();
          }
          _this2.setState({
            active: false
          });
        }
      };
      this.spyHandler = function (y) {
        var scrollSpyContainer = _this2.getScrollSpyContainer();
        if (scrollHash.isMounted() && !scrollHash.isInitialized()) {
          return;
        }
        var to = _this2.props.to;
        var element = null;
        var elemTopBound = 0;
        var elemBottomBound = 0;
        var containerTop = 0;
        if (scrollSpyContainer.getBoundingClientRect) {
          var containerCords = scrollSpyContainer.getBoundingClientRect();
          containerTop = containerCords.top;
        }
        if (!element || _this2.props.isDynamic) {
          element = scroller.get(to);
          if (!element) {
            return;
          }
          var cords = element.getBoundingClientRect();
          elemTopBound = cords.top - containerTop + y;
          elemBottomBound = elemTopBound + cords.height;
        }
        var offsetY = y - _this2.props.offset;
        var isInside = offsetY >= Math.floor(elemTopBound) && offsetY < Math.floor(elemBottomBound);
        var isOutside = offsetY < Math.floor(elemTopBound) || offsetY >= Math.floor(elemBottomBound);
        var activeLink = scroller.getActiveLink();
        if (isOutside) {
          if (to === activeLink) {
            scroller.setActiveLink(void 0);
          }
          if (_this2.props.hashSpy && scrollHash.getHash() === to) {
            scrollHash.changeHash();
          }
          if (_this2.props.spy && _this2.state.active) {
            _this2.setState({
              active: false
            });
            _this2.props.onSetInactive && _this2.props.onSetInactive();
          }
          return scrollSpy.updateStates();
        }
        if (isInside && activeLink !== to) {
          scroller.setActiveLink(to);
          _this2.props.hashSpy && scrollHash.changeHash(to);
          if (_this2.props.spy) {
            _this2.setState({
              active: true
            });
            _this2.props.onSetActive && _this2.props.onSetActive(to);
          }
          return scrollSpy.updateStates();
        }
      };
    };
    ;
    Scroll.propTypes = protoTypes;
    Scroll.defaultProps = {
      offset: 0
    };
    return Scroll;
  },
  Element: function Element(Component) {
    console.warn("Helpers.Element is deprecated since v1.7.0");
    var Element = function (_React$Component2) {
      _inherits(Element, _React$Component2);
      function Element(props) {
        _classCallCheck(this, Element);
        var _this3 = _possibleConstructorReturn(this, (Element.__proto__ || Object.getPrototypeOf(Element)).call(this, props));
        _this3.childBindings = {
          domNode: null
        };
        return _this3;
      }
      _createClass(Element, [{
        key: "componentDidMount",
        value: function componentDidMount() {
          if (typeof window === "undefined") {
            return false;
          }
          this.registerElems(this.props.name);
        }
      }, {
        key: "componentDidUpdate",
        value: function componentDidUpdate(prevProps) {
          if (this.props.name !== prevProps.name) {
            this.registerElems(this.props.name);
          }
        }
      }, {
        key: "componentWillUnmount",
        value: function componentWillUnmount() {
          if (typeof window === "undefined") {
            return false;
          }
          defaultScroller.unregister(this.props.name);
        }
      }, {
        key: "registerElems",
        value: function registerElems(name) {
          defaultScroller.register(name, this.childBindings.domNode);
        }
      }, {
        key: "render",
        value: function render() {
          return React.createElement(Component, _extends({}, this.props, {
            parentBindings: this.childBindings
          }));
        }
      }]);
      return Element;
    }(React.Component);
    ;
    Element.propTypes = {
      name: PropTypes.string,
      id: PropTypes.string
    };
    return Element;
  }
};
exports = Helpers;
export default exports;