import * as _react4 from "react";
var _react3 = "default" in _react4 ? _react4.default : _react4;
import _scrollLink3 from "../mixins/scroll-link";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _react = _react3;
var _react2 = _interopRequireDefault(_react);
var _scrollLink = _scrollLink3;
var _scrollLink2 = _interopRequireDefault(_scrollLink);
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}
function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }
  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}
function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}
var LinkElement = function (_React$Component) {
  _inherits(LinkElement, _React$Component);
  function LinkElement() {
    var _ref;
    var _temp, _this, _ret;
    _classCallCheck(this, LinkElement);
    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = LinkElement.__proto__ || Object.getPrototypeOf(LinkElement)).call.apply(_ref, [this].concat(args))), _this), _this.render = function () {
      return _react2.default.createElement("a", _this.props, _this.props.children);
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }
  return LinkElement;
}(_react2.default.Component);
;
exports.default = (0, _scrollLink2.default)(LinkElement);
export default exports;