import * as _react4 from "react";
var _react3 = "default" in _react4 ? _react4.default : _react4;
import _scrollElement3 from "../mixins/scroll-element";
import * as _propTypes4 from "prop-types";
var _propTypes3 = "default" in _propTypes4 ? _propTypes4.default : _propTypes4;
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];
    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }
  return target;
};
var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }
  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();
var _react = _react3;
var _react2 = _interopRequireDefault(_react);
var _scrollElement = _scrollElement3;
var _scrollElement2 = _interopRequireDefault(_scrollElement);
var _propTypes = _propTypes3;
var _propTypes2 = _interopRequireDefault(_propTypes);
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}
function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }
  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}
function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}
var ElementWrapper = function (_React$Component) {
  _inherits(ElementWrapper, _React$Component);
  function ElementWrapper() {
    _classCallCheck(this, ElementWrapper);
    return _possibleConstructorReturn(this, (ElementWrapper.__proto__ || Object.getPrototypeOf(ElementWrapper)).apply(this, arguments));
  }
  _createClass(ElementWrapper, [{
    key: "render",
    value: function render() {
      var _this2 = this;

      // Remove `parentBindings` and `name` from props
      var newProps = _extends({}, this.props);
      delete newProps.name;
      if (newProps.parentBindings) {
        delete newProps.parentBindings;
      }
      return _react2.default.createElement("div", _extends({}, newProps, {
        ref: function ref(el) {
          _this2.props.parentBindings.domNode = el;
        }
      }), this.props.children);
    }
  }]);
  return ElementWrapper;
}(_react2.default.Component);
;
ElementWrapper.propTypes = {
  name: _propTypes2.default.string,
  id: _propTypes2.default.string
};
exports.default = (0, _scrollElement2.default)(ElementWrapper);
export default exports;