import _utils3 from "./utils";
import _animateScroll3 from "./animate-scroll";
import _scrollEvents3 from "./scroll-events";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];
    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }
  return target;
};
var _utils = _utils3;
var _utils2 = _interopRequireDefault(_utils);
var _animateScroll = _animateScroll3;
var _animateScroll2 = _interopRequireDefault(_animateScroll);
var _scrollEvents = _scrollEvents3;
var _scrollEvents2 = _interopRequireDefault(_scrollEvents);
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var __mapped = {};
var __activeLink = void 0;
exports.default = {
  unmount: function unmount() {
    __mapped = {};
  },
  register: function register(name, element) {
    __mapped[name] = element;
  },
  unregister: function unregister(name) {
    delete __mapped[name];
  },
  get: function get(name) {
    return __mapped[name] || document.getElementById(name) || document.getElementsByName(name)[0] || document.getElementsByClassName(name)[0];
  },
  setActiveLink: function setActiveLink(link) {
    return __activeLink = link;
  },
  getActiveLink: function getActiveLink() {
    return __activeLink;
  },
  scrollTo: function scrollTo(to, props) {
    var target = this.get(to);
    if (!target) {
      console.warn("target Element not found");
      return;
    }
    props = _extends({}, props, {
      absolute: false
    });
    var containerId = props.containerId;
    var container = props.container;
    var containerElement = void 0;
    if (containerId) {
      containerElement = document.getElementById(containerId);
    } else if (container && container.nodeType) {
      containerElement = container;
    } else {
      containerElement = document;
    }
    props.absolute = true;
    var horizontal = props.horizontal;
    var scrollOffset = _utils2.default.scrollOffset(containerElement, target, horizontal) + (props.offset || 0);

    /*
     * if animate is not provided just scroll into the view
     */
    if (!props.smooth) {
      if (_scrollEvents2.default.registered["begin"]) {
        _scrollEvents2.default.registered["begin"](to, target);
      }
      if (containerElement === document) {
        if (props.horizontal) {
          window.scrollTo(scrollOffset, 0);
        } else {
          window.scrollTo(0, scrollOffset);
        }
      } else {
        containerElement.scrollTop = scrollOffset;
      }
      if (_scrollEvents2.default.registered["end"]) {
        _scrollEvents2.default.registered["end"](to, target);
      }
      return;
    }

    /*
     * Animate scrolling
     */

    _animateScroll2.default.animateTopScroll(scrollOffset, props, to, target);
  }
};
export default exports;